import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { colors } from './colors';

const theme = extendTheme(
  {
    colors,
    fonts: {
      body: "'IBM Plex Sans', sans-serif",
      heading: "'IBM Plex Sans', sans-serif",
      mono: "'IBM Plex Mono', monospace",
    },
  },
  withDefaultColorScheme({ colorScheme: 'violet' }),
);

export default theme;
