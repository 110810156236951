export const colors = {
  gray: {
    50: '#e9f3fe',
    100: '#d1dae0',
    200: '#b8c0c5',
    300: '#9fa7ac',
    400: '#858e93',
    500: '#6c757a',
    600: '#535b60',
    700: '#3a4146',
    800: '#20282d',
    900: '#020e17',
  },
  blue: {
    50: '#e1f6ff',
    100: '#bbdef6',
    200: '#93c7eb',
    300: '#6ab0e1',
    400: '#4399d8',
    500: '#2a80be',
    600: '#1e6495',
    700: '#11476b',
    800: '#042b43',
    900: '#000f1b',
  },
  indigo: {
    50: '#e9eaff',
    100: '#bec3f5',
    200: '#959bea',
    300: '#6b73e1',
    400: '#414bd7',
    500: '#2931be',
    600: '#1e2694',
    700: '#151b6a',
    800: '#0b1042',
    900: '#03041a',
  },
  violet: {
    50: '#f7eaff',
    100: '#ddc6ef',
    200: '#c5a0df',
    300: '#ac7cd1',
    400: '#9456c3',
    500: '#7b3ca9',
    600: '#602f84',
    700: '#442160',
    800: '#2a133b',
    900: '#110519',
  },
  fuschia: {
    50: '#fde8ff',
    100: '#efbef4',
    200: '#e395e9',
    300: '#d76ce0',
    400: '#cc42d7',
    500: '#b32abe',
    600: '#8b1f93',
    700: '#64166a',
    800: '#3d0b41',
    900: '#170219',
  },
  pink: {
    50: '#ffe7f7',
    100: '#f4bede',
    200: '#ea95c6',
    300: '#e06baf',
    400: '#d74298',
    500: '#be297f',
    600: '#942063',
    700: '#6a1547',
    800: '#410b2a',
    900: '#1a0110',
  },
  red: {
    50: '#ffe7e9',
    100: '#f5bec3',
    200: '#ea949c',
    300: '#e16b75',
    400: '#d7414e',
    500: '#be2934',
    600: '#941e28',
    700: '#6b151d',
    800: '#410b10',
    900: '#1b0104',
  },
  orange: {
    50: '#ffefe1',
    100: '#f5d4bb',
    200: '#ebb994',
    300: '#e19c6a',
    400: '#d88041',
    500: '#be6727',
    600: '#95501e',
    700: '#6b3914',
    800: '#422109',
    900: '#1b0800',
  },
  yellow: {
    50: '#fdf9df',
    100: '#f2eeba',
    200: '#eae392',
    300: '#e1d768',
    400: '#d9cc40',
    500: '#bfb326',
    600: '#958b1c',
    700: '#6a6311',
    800: '#403c06',
    900: '#161400',
  },
  lime: {
    50: '#f1fde1',
    100: '#dbf3ba',
    200: '#c5ea93',
    300: '#afe169',
    400: '#99d940',
    500: '#80bf26',
    600: '#62951c',
    700: '#466a11',
    800: '#294007',
    900: '#0a1600',
  },
  green: {
    50: '#e5fde4',
    100: '#c0f3bc',
    200: '#9bea94',
    300: '#74e16a',
    400: '#4ed941',
    500: '#35bf28',
    600: '#28951e',
    700: '#1a6a14',
    800: '#0c400a',
    900: '#001700',
  },
  teal: {
    50: '#e0feed',
    100: '#baf3d2',
    200: '#92ebb7',
    300: '#69e19a',
    400: '#40d87f',
    500: '#27bf66',
    600: '#1b944e',
    700: '#0f6a37',
    800: '#034120',
    900: '#001705',
  },
  cyan: {
    50: '#dcfefb',
    100: '#b7f4ef',
    200: '#90ebe3',
    300: '#67e1d7',
    400: '#40d9cc',
    500: '#26bfb2',
    600: '#17958b',
    700: '#076b63',
    800: '#00413b',
    900: '#001816',
  },
};
